@import url('https://fonts.googleapis.com/css?family=Raleway:100,200,300');
@import url('https://fonts.googleapis.com/css?family=Rubik:100,200,300');

/* Overall Nav Bar Styles */

.nav-bar-outer {
    position: fixed;
    background-color: white;
    width: 100%;
    top: 0;
    left: 0;
    height: 10vh;
    z-index: 999; 
}

.nav-bar {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1440px;
    height: 10vh;
    color: #DB4949;
    letter-spacing: 1.2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

/* Right Menu Styles */

#menu-list {
    list-style-type: none;
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-decoration: none;
    font-family: 'Raleway';
    font-size: 1.4em;
    font-weight: 300;
    margin-right: 60px;
}

.spacer {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.menu-list-item {
    padding: 0 0.4rem;
    z-index: 500;
    margin: 0 10px 0 10px;
}

.menu-list-item:hover {
    cursor: pointer;
}

.dropdown {
    visibility: hidden;
    opacity: 0;
    list-style-type: none;
    text-decoration: none;
    position: absolute;
    min-width: 150px;
    z-index: 2;
}

.menu-list-item:hover .dropdown {
    visibility: visible;
    opacity: 1;
}

.menu-link {
    text-decoration: none;
    padding: 6px;
    display: block;
}


/* Dropdown Menu Styles */

.dropdown-item {
    font-size: 0.9em;
    color: #DB4949;
    padding: 6px;

    background-color: white;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    display: block;
    padding: 12px 16px;
    text-decoration: none;
}

.dropdown li:last-child .dropdown-item {
    -moz-border-radius-bottomleft: 6px;

    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.dropdown li:first-child .dropdown-item {
    -moz-border-radius-topleft: 6px;

    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

/* Just for non-link test sub menu items for now */
.dropdown li:last-child  {
    -moz-border-radius-bottomleft: 6px;

    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.dropdown li:first-child {
    -moz-border-radius-topleft: 6px;

    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

/* */

.dropdown-item:hover {
    background-color: #DB4949;;
    color: white;
}


/* Left Menu (logo side) Styles */

#menu-left {
  margin-left: 1rem;
}

#cog {
    height: 43px;
    width: 43px;
    float: left;
    display: inline;
    margin-right: 15px;
}

#title {
    font-family: 'Roboto Condensed', sans-serif;
    float: left;
    display: inline;
    position: relative;
    top: -2px;
}


/* Navlink Styles */

.inactive {
    color: #DB4949;
    text-decoration: none;
}

.active-logo {
    color: #DB4949;
    text-decoration: none;
}

.active {
    color: #DB4949;
    text-decoration: none;
}


/* Responsive menu visibility */

@media (max-width: 1120px) {
    #menu-list {
        display: none;
    }

    #title {
        display: none;
    }
}
