/* Google Fonts */

@import url('https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,700');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:700,800');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300');

/* Landing Styles */

#landing {
  height: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #e13838;
}

#description-container {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 100;
  color: white;
  margin-left: 20px;
  padding: 50px 25px 50px 0;
}

#description-body {
  border-left: 2px solid white;
  padding-left: 20px;
  font-size: 1.1em;
  line-height: 1.1em;
}

#description-head {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.9em;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 25px;
  margin-bottom: 20px;
}

#landing_banner {
  width: 1280px;
  float: right;
}

#fizz_logo {
  min-height: 280px;
  position: absolute;
  left: 51%;
  top: 20px;
}

@media only screen and (min-width: 761px) {
  #description-container {
    position: absolute;
    top: 50%;
    transform: translateY( -50% );
    max-width: 600px;
    width: 40%;
  }
}

@media only screen and (max-width: 1280px) {
  #landing_banner {
    float: left;
  }  
}

@media only screen and (max-width: 760px) {
  #landing {
    height: auto;
  }

  #fizz_logo {
    display: none;
  }

  #description-container {
    width: auto;
  }
}

@media only screen and (max-width: 1050px) {
  #description-body {
    font-size: 1em;
  }

  #landing_banner {
    display: none;
  }
}

/* Announcement Styles */

#announcements {
  width: 100%;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.2em;
  font-weight: 200;
}

#announcement-container {
  width: 80%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}

#announcement-header {
  text-transform: uppercase;
  font-family: 'Work Sans';
  color: #919191;
  text-align: center;
}

#announcement-body {
  margin-top: 20px;
}

.announcement-card {
  margin-right: 10px;
  margin-bottom: 10px;
  color: #43464c;
  padding: 10px;
  height: 255px;
}

.announcement-description {
  margin-top: 10px;
}

.announcement-card h3 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid grey;
}

@media only screen and (max-width: 760px) {
  #announcement-header {
    font-size: 1.4em;
  }

  .announcement-card {
    height: auto;
  }
}
