@import url('https://fonts.googleapis.com/css?family=Work+Sans:700,800');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300i');

* {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    background-color: #F8F8F8;
    padding: 0 0 20px 0;
}
  
.content-center {
    width: 100%;
    max-width: 1440px;
    margin: auto;
}

.header-outer {
    width: 100%;
    background-color: #DB4949;
  }
  
.header-inner {
    width: 100%;
    max-width: 1440px;
    margin: auto;
}

hr {
    border-top: 1px solid #4B4343;
}

.component-header {
    font-size: 2.4em;
    font-family: 'Roboto Condensed', sans-serif;
}

.component-title {
    font-size: 2.5em;
    color: whitesmoke;
    font-family: 'Roboto Condensed', sans-serif;
    padding: 10px 3%;
}

.text {
    width: 90%;
    margin-left: 6%;
    top: 50px;
    color: #4B4343;
}

@media only screen and ( max-width: 800px ) {
    .component-title {
        font-size: 6vw;
        padding: 10px 6%;
    }
}