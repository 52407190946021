@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300);
@import url(https://fonts.googleapis.com/css?family=Rubik:100,200,300);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:700,800);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:700,800);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300i);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:700,800);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300i);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300i);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:700,800);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300i);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300i);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300i);
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #ffffff;
}

html {
  height: 100%;
}
/* Overall Nav Bar Styles */

.nav-bar-outer {
    position: fixed;
    background-color: white;
    width: 100%;
    top: 0;
    left: 0;
    height: 10vh;
    z-index: 999; 
}

.nav-bar {
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    max-width: 1440px;
    height: 10vh;
    color: #DB4949;
    letter-spacing: 1.2px;
    display: flex;
    align-items: center;
}

/* Right Menu Styles */

#menu-list {
    list-style-type: none;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    text-decoration: none;
    font-family: 'Raleway';
    font-size: 1.4em;
    font-weight: 300;
    margin-right: 60px;
}

.spacer {
    flex: 1 1;
}

.menu-list-item {
    padding: 0 0.4rem;
    z-index: 500;
    margin: 0 10px 0 10px;
}

.menu-list-item:hover {
    cursor: pointer;
}

.dropdown {
    visibility: hidden;
    opacity: 0;
    list-style-type: none;
    text-decoration: none;
    position: absolute;
    min-width: 150px;
    z-index: 2;
}

.menu-list-item:hover .dropdown {
    visibility: visible;
    opacity: 1;
}

.menu-link {
    text-decoration: none;
    padding: 6px;
    display: block;
}


/* Dropdown Menu Styles */

.dropdown-item {
    font-size: 0.9em;
    color: #DB4949;
    padding: 6px;

    background-color: white;
    transition: all 0.25s;
    display: block;
    padding: 12px 16px;
    text-decoration: none;
}

.dropdown li:last-child .dropdown-item {
    -moz-border-radius-bottomleft: 6px;

    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.dropdown li:first-child .dropdown-item {
    -moz-border-radius-topleft: 6px;

    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

/* Just for non-link test sub menu items for now */
.dropdown li:last-child  {
    -moz-border-radius-bottomleft: 6px;

    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.dropdown li:first-child {
    -moz-border-radius-topleft: 6px;

    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

/* */

.dropdown-item:hover {
    background-color: #DB4949;;
    color: white;
}


/* Left Menu (logo side) Styles */

#menu-left {
  margin-left: 1rem;
}

#cog {
    height: 43px;
    width: 43px;
    float: left;
    display: inline;
    margin-right: 15px;
}

#title {
    font-family: 'Roboto Condensed', sans-serif;
    float: left;
    display: inline;
    position: relative;
    top: -2px;
}


/* Navlink Styles */

.inactive {
    color: #DB4949;
    text-decoration: none;
}

.active-logo {
    color: #DB4949;
    text-decoration: none;
}

.active {
    color: #DB4949;
    text-decoration: none;
}


/* Responsive menu visibility */

@media (max-width: 1120px) {
    #menu-list {
        display: none;
    }

    #title {
        display: none;
    }
}



.toggle-button {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 6vh;
  width: 50px;
  background: transparent;
  border: none;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:hover {
  cursor: pointer;
}

.toggle-button:focus {
  outline: none;
}

.line {
width: 50px;
border: 0;
border-bottom: 3px solid #DB4949;
border-radius: 10px;}

@media (min-width: 1120px) {
  .toggle-button {
    display: none;
  }
}

.side-drawer {
  height: 100%;
  background: white;
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  top: 10vh;
  max-width: 400px;
  font-family: 'Raleway';
  font-size: 1.4em;
  font-weight: 300;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.side-drawer.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.menu-header {
  font-size: 0.9em;
  color: #474b51;
  background-color: white;
  display: block;
  padding: 12px 16px;
  text-decoration: none;
}

@media (min-width: 1120px) {
  .side-drawer {
  display: none;
}

}


#footer {
  width: 100%;
  height:200px;
  background-color: #DB4949;
  display: flex;
  color: white;
  font-family: 'Roboto Condensed';
}

#club-location {
  flex-grow: 3;
  margin-top: 5vh;

}

#footer_fizz_logo {
  float: left;
  display: inline;
  height: 80px;
  width: 100px;
}

#socials {
  width: 100%;
  height: 80px;
  background-color: #c5c8cc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
}

#socials h3 {
  text-transform: uppercase;
  font-family: 'Work Sans';
  color: #434347;
}

#socials ul {
  list-style-type: none;
  flex-grow: 1;
}

#socials ul li {
  display: inline-block;
}


#twitter_logo {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
}

#instagram_logo {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);

}

#location-details {
  float: left;
  display: inline;
}

#filler {
  /* padding-top: 20px; */
flex-grow: 3;
}

#copyrights {
  position: absolute;
bottom: 0;
left: 35%;
}



#contacts {
flex-grow: 3;
 margin-top: 5vh;
}

@media (max-width: 800px ) {
  #contacts {
    display: none;
  }

  #filler {
    flex-grow: 2;
  }

  #copyrights {
    left: 25%;
  }
}

.person .title {
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 3px;
    font-size: 2em;
    color: #4b4343;
    font-weight: 200;
}

.description {
    width: 70%;
    margin-top: 20px;
    font-family: 'Raleway';
    font-size: 1.05em;
}

.person {
    margin: 20px 3% 0;
}

.person .name {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.5em;
    font-weight: 200;
    margin-top: 3px;
}

.div-table {
    display: table;         
    width: 100%;
    margin:1%;        
  }

  .div-table-col {
    float: left; /* fix for  buggy browsers */
    display: table-column;         
    width: 50%;
  }

.photo{
    width: auto;
    height: auto;
}

.person .email {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.3em;
    font-weight: 300;
    font-style: italic;
    margin-top: 3px;
}


@media only screen and (max-width: 1000px) {
    .person .title {
        font-size: 4vw;
    }

    .description {
        font-size: 2vw;
    }
}


@media only screen and (max-width: 800px) {
    .person {
        margin: 20px 6% 0;
    }

    .person .title {
        font-size: 5vw;
    }

    .person .name {
        font-size: 4vw;
    }

    .person .email {
        font-size: 4vw;
    }

    .description {
        font-size: 3.5vw;
    }
}
* {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    background-color: #F8F8F8;
    padding: 0 0 20px 0;
}
  
.content-center {
    width: 100%;
    max-width: 1440px;
    margin: auto;
}

.header-outer {
    width: 100%;
    background-color: #DB4949;
  }
  
.header-inner {
    width: 100%;
    max-width: 1440px;
    margin: auto;
}

hr {
    border-top: 1px solid #4B4343;
}

.component-header {
    font-size: 2.4em;
    font-family: 'Roboto Condensed', sans-serif;
}

.component-title {
    font-size: 2.5em;
    color: whitesmoke;
    font-family: 'Roboto Condensed', sans-serif;
    padding: 10px 3%;
}

.text {
    width: 90%;
    margin-left: 6%;
    top: 50px;
    color: #4B4343;
}

@media only screen and ( max-width: 800px ) {
    .component-title {
        font-size: 6vw;
        padding: 10px 6%;
    }
}
/** Question Styles **/

.qa .title {
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 3px;
    font-size: 2em;
    color: #4b4343;
}

.content {
    margin-top: 20px;
    font-family: 'Raleway';
    font-size: 1.07em;
}

.qa {
    width: 60%;
    margin: 20px 3%;
}

@media only screen and (max-width: 1000px) {
    .title {
        font-size: 4vw;
    }

    .content {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 800px) {
    .title {
        font-size: 100;
    }

    .qa {
        width: 90%;
        margin: 20px 6%;
    }

    .content {
        font-size: 3.5vw;
    }
}
/* Google Fonts */

/* Landing Styles */

#landing {
  height: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #e13838;
}

#description-container {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 100;
  color: white;
  margin-left: 20px;
  padding: 50px 25px 50px 0;
}

#description-body {
  border-left: 2px solid white;
  padding-left: 20px;
  font-size: 1.1em;
  line-height: 1.1em;
}

#description-head {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.9em;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 25px;
  margin-bottom: 20px;
}

#landing_banner {
  width: 1280px;
  float: right;
}

#fizz_logo {
  min-height: 280px;
  position: absolute;
  left: 51%;
  top: 20px;
}

@media only screen and (min-width: 761px) {
  #description-container {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY( -50% );
            transform: translateY( -50% );
    max-width: 600px;
    width: 40%;
  }
}

@media only screen and (max-width: 1280px) {
  #landing_banner {
    float: left;
  }  
}

@media only screen and (max-width: 760px) {
  #landing {
    height: auto;
  }

  #fizz_logo {
    display: none;
  }

  #description-container {
    width: auto;
  }
}

@media only screen and (max-width: 1050px) {
  #description-body {
    font-size: 1em;
  }

  #landing_banner {
    display: none;
  }
}

/* Announcement Styles */

#announcements {
  width: 100%;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.2em;
  font-weight: 200;
}

#announcement-container {
  width: 80%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
}

#announcement-header {
  text-transform: uppercase;
  font-family: 'Work Sans';
  color: #919191;
  text-align: center;
}

#announcement-body {
  margin-top: 20px;
}

.announcement-card {
  margin-right: 10px;
  margin-bottom: 10px;
  color: #43464c;
  padding: 10px;
  height: 255px;
}

.announcement-description {
  margin-top: 10px;
}

.announcement-card h3 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid grey;
}

@media only screen and (max-width: 760px) {
  #announcement-header {
    font-size: 1.4em;
  }

  .announcement-card {
    height: auto;
  }
}

/** Question Styles **/

.cd .title {
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 3px;
    font-size: 2em;
    color: #4b4343;
    margin-bottom: 10px;
}

.content {
    margin-top: 20px;
    font-family: 'Raleway';
    font-size: 1.07em;
    margin-bottom: 20px;
}

.cd {
    width: 100%;
    margin: 20px 3%;
}
.btn{
    font-family: 'Raleway';
}

@media only screen and (max-width: 1000px) {
    .title {
        font-size: 4vw;
    }

    .content {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 800px) {
    .title {
        font-size: 100;
    }

    .qa {
        width: 90%;
        margin: 20px 6%;
    }

    .content {
        font-size: 3.5vw;
    }
}
.btn.action-button:hover {
    background-color: rgb(255, 136, 107);
  }
.btn.btn-light{
    background-color: rgb(255, 60, 0);
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #f7fafd;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    margin: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-decoration: none
  }
/** Question Styles **/

.cd .title {
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 3px;
    font-size: 2em;
    color: #4b4343;
}

.filmtitle {
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 3px;
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #4b4343;
}

.content {
    margin-top: 20px;
    font-family: 'Raleway';
    font-size: 1.07em;
    margin-right: 40px;
}

.cd {
    width: 100%;
    margin: 20px 3%;
}

.btn{
    font-family: 'Raleway';
}

@media only screen and (max-width: 1000px) {
    .title {
        font-size: 4vw;
    }

    .content {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 800px) {
    .title {
        font-size: 100;
    }

    .qa {
        width: 90%;
        margin: 20px 6%;
    }

    .content {
        font-size: 3.5vw;
    }
}
/** Question Styles **/

.qa .title {
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 3px;
    font-size: 2em;
    color: #4b4343;
}

.content {
    margin-top: 20px;
    font-family: 'Raleway';
    font-size: 1.07em;
}

.qa {
    width: 60%;
    margin: 20px 3%;
}

.iframe {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1000px) {
    .title {
        font-size: 4vw;
    }

    .content {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 800px) {
    .title {
        font-size: 100;
    }

    .qa {
        width: 90%;
        margin: 20px 6%;
    }

    .content {
        font-size: 3.5vw;
    }
}
