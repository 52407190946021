.btn.action-button:hover {
    background-color: rgb(255, 136, 107);
  }
.btn.btn-light{
    background-color: rgb(255, 60, 0);
}
.btn {
    display: inline-block;
    font-weight: 400;
    color: #f7fafd;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    margin: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-decoration: none
  }