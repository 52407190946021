
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:700,800');

#footer {
  width: 100%;
  height:200px;
  background-color: #DB4949;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: white;
  font-family: 'Roboto Condensed';
}

#club-location {
  -webkit-box-flex: 3;
      -ms-flex-positive: 3;
          flex-grow: 3;
  margin-top: 5vh;

}

#footer_fizz_logo {
  float: left;
  display: inline;
  height: 80px;
  width: 100px;
}

#socials {
  width: 100%;
  height: 80px;
  background-color: #c5c8cc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 5px;
}

#socials h3 {
  text-transform: uppercase;
  font-family: 'Work Sans';
  color: #434347;
}

#socials ul {
  list-style-type: none;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

#socials ul li {
  display: inline-block;
}


#twitter_logo {
  -webkit-transform: translateY(-6px);
      -ms-transform: translateY(-6px);
          transform: translateY(-6px);
}

#instagram_logo {
  -webkit-transform: translateY(-6px);
      -ms-transform: translateY(-6px);
          transform: translateY(-6px);

}

#location-details {
  float: left;
  display: inline;
}

#filler {
  /* padding-top: 20px; */
-webkit-box-flex: 3;
    -ms-flex-positive: 3;
        flex-grow: 3;
}

#copyrights {
  position: absolute;
bottom: 0;
left: 35%;
}



#contacts {
-webkit-box-flex: 3;
    -ms-flex-positive: 3;
        flex-grow: 3;
 margin-top: 5vh;
}

@media (max-width: 800px ) {
  #contacts {
    display: none;
  }

  #filler {
    -webkit-box-flex: 2;
        -ms-flex-positive: 2;
            flex-grow: 2;
  }

  #copyrights {
    left: 25%;
  }
}
