@import url('https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:100,200,300i');

/** Question Styles **/

.cd .title {
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 3px;
    font-size: 2em;
    color: #4b4343;
}

.filmtitle {
    font-family: 'Roboto Condensed', sans-serif;
    margin-top: 3px;
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #4b4343;
}

.content {
    margin-top: 20px;
    font-family: 'Raleway';
    font-size: 1.07em;
    margin-right: 40px;
}

.cd {
    width: 100%;
    margin: 20px 3%;
}

.btn{
    font-family: 'Raleway';
}

@media only screen and (max-width: 1000px) {
    .title {
        font-size: 4vw;
    }

    .content {
        font-size: 2vw;
    }
}

@media only screen and (max-width: 800px) {
    .title {
        font-size: 100;
    }

    .qa {
        width: 90%;
        margin: 20px 6%;
    }

    .content {
        font-size: 3.5vw;
    }
}